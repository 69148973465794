'use client';

import { useForm } from 'react-hook-form';

import Image from 'next/image';

import type { AuthProviderType, AuthCredentials } from '@/config/firebase/auth';
import { useAuth } from '@/providers/auth-provider';
import { SchemaLoginProps, schemaLogin } from '@/utils/schema/login';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@mui/material';
import { useTheme } from 'styled-components';

import { Button, Checkbox, Divider, Input, LinkButton } from '@/components';

import * as S from './styles';

const LayoutLogin = () => {
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.laptop);
  const { formState, register, handleSubmit, setValue, setError } = useForm({
    resolver: yupResolver(schemaLogin),
  });
  const { isLoading, signIn } = useAuth();

  const handleLogin = async (
    authProvider: AuthProviderType,
    credentials?: AuthCredentials
  ) => {
    try {
      await signIn(authProvider, credentials);
    } catch (error: any) {
      if (error?.code === 'auth/user-not-found') {
        return setError('email', {
          type: 'manual',
          message: error.message,
        });
      }

      setError('password', {
        type: 'manual',
        message: error.message || 'Aconteceu um erro desconhecido',
      });
    }
  };

  return (
    <S.Main data-testid="layoutLogin">
      <section className="sectionForm">
        <S.WrapperImage>
          <Image
            src="/assets/images/logo-preto-verde.png"
            alt="Logo assiny"
            width={140}
            height={50}
            priority
          />
        </S.WrapperImage>

        <S.Title>Bem-vindo de volta!</S.Title>
        <S.Subtitle>Insira seus dados de login</S.Subtitle>

        <Button
          color="white"
          className="buttonGoogle"
          type="button"
          icon={
            <Image
              src="/assets/images/logo-google.png"
              alt="Logo"
              width={23}
              height={23}
            />
          }
          onClick={async () => await handleLogin('google')}
          size="md"
          disable={isLoading}>
          Entrar com Google
        </Button>
        <Button
          color="white"
          className="buttonOutlock"
          type="button"
          size="md"
          icon={
            <Image
              src="/assets/images/outlook-icon.png"
              alt="Logo"
              width={23}
              height={23}
            />
          }
          disable={isLoading}
          onClick={async () => await handleLogin('microsoft')}>
          Entrar com Outlook
        </Button>

        <Divider className="divider" variant="primary" />

        <form
          method="POST"
          onSubmit={handleSubmit((data: SchemaLoginProps) =>
            handleLogin('password', data)
          )}>
          <Input
            data-test="email"
            label="E-mail"
            placeholder="Digite seu e-mail"
            classNameWrapper="email"
            name="email"
            errorMessage={formState.errors.email?.message}
            error={!!formState.errors.email}
            register={register}
          />

          <Input
            password
            classNameWrapper="password"
            data-test="password"
            label="Senha"
            placeholder="Digite sua senha"
            name="password"
            errorMessage={formState.errors.password?.message}
            error={!!formState.errors.password}
            register={register}
          />

          <div className="wrapperForgotPassword">
            <Checkbox
              size="sm"
              label="Lembrar-me"
              onChange={(value) => {
                setValue('rememberMe', Boolean(value));
              }}
            />

            <LinkButton size="sm" href="/forgot">
              Esqueci a senha
            </LinkButton>
          </div>

          <Button
            data-test="button-login"
            color="primary"
            className="buttonLogin"
            type="submit"
            size="md"
            disable={isLoading}>
            Iniciar sessão
          </Button>
        </form>

        <div className="wrapperNotAccount">
          <S.Description>Não tem uma conta</S.Description>
          <LinkButton size="sm" href="/register">
            Cadastre-se
          </LinkButton>
        </div>
      </section>

      {isLaptop && (
        <section className="sectionImage">
          <Image
            priority
            fill
            src="/assets/images/gerencie-agil-pratico.png"
            alt="Gerencie de forma ágil e prática, sem complicação!"
            sizes="(max-width: 768px) 100%, (max-width: 1200px) 100%"
          />
        </section>
      )}
    </S.Main>
  );
};

export { LayoutLogin };
